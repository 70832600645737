<template>
  <v-card class="pt-3">
    <v-card-text>
      <v-form ref="form">
        <v-row>
          <v-col cols="12" md="6">
            <v-select
              :loading="eventLoading"
              dense
              v-model="household.event_id"
              :items="eventAttendingItems"
              item-text="label"
              item-value="event_id"
              label="Event Attending"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              dense
              v-model="household.status"
              :items="seminarStatusItems"
              label="Seminar Status"
              placeholder="Select"
              :rules="req"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <div v-for="(person, index) in household.people" :key="index">
              <div v-if="person.primary">
                <v-text-field
                  dense
                  v-model="person.name"
                  label="First and Last Name"
                  type=""
                  :rules="req"
                ></v-text-field>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="6" v-if="household.people.length == 2">
            <div v-for="(person, index) in household.people" :key="index">
              <div v-if="!person.primary">
                <v-text-field
                  dense
                  v-model="person.name"
                  label="Spouse First and Last Name"
                  type=""
                  :rules="req"
                >
                  <v-tooltip bottom slot="append">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        @click="removePerson"
                        slot="append"
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-minus-circle-outline
                      </v-icon>
                    </template>
                    <span>Remove Spouse</span>
                  </v-tooltip>
                </v-text-field>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="6" v-else>
            <v-tooltip bottom slot="append">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="addPerson"
                  slot="append"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-plus-circle-outline
                </v-icon>
              </template>
              <span>Add Spouse</span>
            </v-tooltip>
            Add Spouse
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              dense
              v-model="household.phone"
              label="Phone Number"
              type="tel"
              :rules="req"
              autocomplete="tel"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="household.email"
              label="E-mail"
              dense
              placeholder="E-mail"
              :rules="req"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              dense
              v-model="household.street"
              label="Address Line"
              autocomplete="shipping street-address"
              :rules="req"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              dense
              v-model="household.city"
              label="City"
              :rules="req"
              autocomplete="shipping locality"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              v-model="household.state"
              :items="all_states"
              label="State"
              item-value="abbreviation"
              item-text="name"
              dense
              :rules="[(v) => !!v || 'State is ']"
            ></v-select>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              dense
              v-model="household.zip"
              label="ZIP / Postal Code"
              :rules="req"
              autocomplete="shipping postal-code"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn color="error" @click="delete_household">Delete</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        class="mb-5 action-btn"
        color="primary"
        @click="update"
        :loading="loading"
      >
        Update
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import hasuraService from "@/services/hasura.service.js";
import dayjs from "dayjs";

export default {
  name: "HouseholdDetail",
  mixins: [hasuraService],
  mounted() {
    this.getEvents();
  },
  watch: {
    event_id: function(newval) {
      this.household.campaign_id = this.eventAttendingItems.filter(
        (item) => item.event_id == newval
      )[0].campaign_id;
    },
    "household.status": function(newVal) {
      console.log(newVal);
      if (newVal == "overflow") {
        this.event_id = null;
      }
    },
  },
  computed: {
    event_id: {
      get() {
        return this.household.event_id;
      },
      set(value) {
        this.household.event_id = value;
      },
    },
  },
  methods: {
    async delete_household() {
      this.loading = true;
      await this.delete_household_by_pk(this.household.id);
      this.$store.commit(
        "set_snackbar",
        "Household Deleted, please refresh page to see changes"
      );

      this.loading = false;
    },
    async update() {
      this.loading = true;
      if (this.$refs.form.validate()) {
        // update household
        await this.update_household_by_pk(this.household.id, this.household);
        // add people
        for (let person of this.household.people) {
          if (this.household.people.length == 1) {
            person.primary = true;
          }
          if (person.id) {
            await this.update_person_by_pk(person.id, person);
          } else {
            let p = await this.insert_person(this.household.id, person);
            person.id = p.id;
          }
        }

        // notify success

        this.$store.commit("set_snackbar", "Household updated successfully");
      } else {
        this.$store.commit("set_snackbar", "Please fill out all fields in red");
      }

      this.loading = false;
    },
    getEvents() {
      this.eventLoading = true;
      this.getEventsbyUser(this.household.user_id).then((response) => {
        this.eventLoading = false;
        this.eventAttendingItems = [
          {
            label: "None",
            event_id: null,
            campaign_id: this.household.campaign_id,
            start_date: null,
          },
        ].concat(
          response
            .map((event) => {
              return {
                event_id: event.event_id,
                campaign_id: event.campaign_id,
                start_date: event.start_date,
                label: `${this.formatDate(event.start_date)} - ${
                  event.venue_name
                }`,
              };
            })
            .sort((a, b) => {
              return dayjs(a.start_date).isBefore(b.start_date) ? 1 : -1;
            })
        );
        console.log(this.eventAttendingItems);
      });
    },
    formatDate(date) {
      return dayjs(date).format("M/D/YY");
    },
    addPerson() {
      this.household.people.push({
        name: "",
        primary: false,
      });
    },
    removePerson() {
      let person = this.household.people[this.household.people.length - 1];
      if (person.id) {
        this.delete_person_by_pk(person.id);
      }
      this.household.people.pop();
    },
  },

  props: {
    household: Object,
  },
  data() {
    return {
      seminarStatusItems: [
        { text: "Registered", value: "registered" },
        { text: "Overflow", value: "overflow" },
      ],
      eventAttending: "",
      eventAttendingItems: [],
      loading: false,
      eventLoading: false,
      req: [(v) => !!v || "Field is required"],
      valid: true,
      all_states: [
        { name: "ALABAMA", abbreviation: "AL" },
        { name: "ALASKA", abbreviation: "AK" },
        { name: "ARIZONA", abbreviation: "AZ" },
        { name: "ARKANSAS", abbreviation: "AR" },
        { name: "CALIFORNIA", abbreviation: "CA" },
        { name: "COLORADO", abbreviation: "CO" },
        { name: "CONNECTICUT", abbreviation: "CT" },
        { name: "DELAWARE", abbreviation: "DE" },
        { name: "DISTRICT OF COLUMBIA", abbreviation: "DC" },
        { name: "FLORIDA", abbreviation: "FL" },
        { name: "GEORGIA", abbreviation: "GA" },
        { name: "HAWAII", abbreviation: "HI" },
        { name: "IDAHO", abbreviation: "ID" },
        { name: "ILLINOIS", abbreviation: "IL" },
        { name: "INDIANA", abbreviation: "IN" },
        { name: "IOWA", abbreviation: "IA" },
        { name: "KANSAS", abbreviation: "KS" },
        { name: "KENTUCKY", abbreviation: "KY" },
        { name: "LOUISIANA", abbreviation: "LA" },
        { name: "MAINE", abbreviation: "ME" },
        { name: "MARYLAND", abbreviation: "MD" },
        { name: "MASSACHUSETTS", abbreviation: "MA" },
        { name: "MICHIGAN", abbreviation: "MI" },
        { name: "MINNESOTA", abbreviation: "MN" },
        { name: "MISSISSIPPI", abbreviation: "MS" },
        { name: "MISSOURI", abbreviation: "MO" },
        { name: "MONTANA", abbreviation: "MT" },
        { name: "NEBRASKA", abbreviation: "NE" },
        { name: "NEVADA", abbreviation: "NV" },
        { name: "NEW HAMPSHIRE", abbreviation: "NH" },
        { name: "NEW JERSEY", abbreviation: "NJ" },
        { name: "NEW MEXICO", abbreviation: "NM" },
        { name: "NEW YORK", abbreviation: "NY" },
        { name: "NORTH CAROLINA", abbreviation: "NC" },
        { name: "NORTH DAKOTA", abbreviation: "ND" },
        { name: "OHIO", abbreviation: "OH" },
        { name: "OKLAHOMA", abbreviation: "OK" },
        { name: "OREGON", abbreviation: "OR" },
        { name: "PALAU", abbreviation: "PW" },
        { name: "PENNSYLVANIA", abbreviation: "PA" },
        { name: "PUERTO RICO", abbreviation: "PR" },
        { name: "RHODE ISLAND", abbreviation: "RI" },
        { name: "SOUTH CAROLINA", abbreviation: "SC" },
        { name: "SOUTH DAKOTA", abbreviation: "SD" },
        { name: "TENNESSEE", abbreviation: "TN" },
        { name: "TEXAS", abbreviation: "TX" },
        { name: "UTAH", abbreviation: "UT" },
        { name: "VERMONT", abbreviation: "VT" },
        { name: "VIRGIN ISLANDS", abbreviation: "VI" },
        { name: "VIRGINIA", abbreviation: "VA" },
        { name: "WASHINGTON", abbreviation: "WA" },
        { name: "WEST VIRGINIA", abbreviation: "WV" },
        { name: "WISCONSIN", abbreviation: "WI" },
        { name: "WYOMING", abbreviation: "WY" },
      ],
    };
  },
};
</script>
